<template>
  <div class="product-cell">
    <checkbox v-model="product.isSelected"></checkbox>
    <div
      class="product"
      @click="getProductDetails(product.ProductNumber, product.ShopId)"
    >
      <van-image class="photo" :src="productImg">
        <template v-slot:error>
          <img class="ImgIcon" src="~assets/img/other/empty.gif" />
        </template>
      </van-image>

      <div class="content">
        <div class="title">
          <div class="discount" v-if="product.IsCouponProduct">
            {{ $t.discount }}
          </div>
          <span class="name">{{ product.ProductName }}</span>
        </div>
        <div class="notice" v-if="product.IsUser == 0">
          <span v-if="product.ProductState == 2"
            >( {{ $t.cart.inventoryState1 }} )</span
          >
          <span v-if="product.ProductState == 3"
            >( {{ $t.cart.inventoryState2 }} )</span
          >
          <span v-if="product.ProductState == 4"
            >( {{ $t.cart.productOff }} )</span
          >
        </div>
        <p class="detail">
          {{ product.ProductModelStr | productModelStrFilter }}
        </p>
        <div class="price-count">
          <p class="price">
            $
            <span>{{ product.NowMoney | formatPrice }}</span>
          </p>
          <div class="count">
            <img
              src="~assets/img/shopping/icon_buy_reduction.png"
              class="remove"
              @click.stop="actionReduction"
            />
            <input
              type="number"
              class="field"
              v-model="product.Number"
              @click="stop"
              @blur="actionNumberInputBlur"
            />
            <img
              src="~assets/img/shopping/icon_buy_increase.png"
              class="add"
              @click.stop="actionIncrease"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "./Checkbox";
import Vue from "vue";
import { Image } from "vant";

Vue.use(Image);
export default {
  name: "ProductCell",
  components: {
    Checkbox,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    productImg() {
      return this.$api.formatImageUrl(this.product.ProductPhoto.split("|")[0]);
    },
  },
  filters: {
    formatPrice(val) {
      var str = val.toFixed(2) + "";
      var intSum = str
        .substring(0, str.indexOf("."))
        .replace(/\B(?=(?:\d{3})+$)/g, ","); // 取到整数部分
      var dot = str.substring(str.length, str.indexOf(".")); // 取到小数部分搜索
      var ret = intSum + dot;
      return ret;
    },
    // 规则过滤器
    productModelStrFilter(value) {
      return value.replace(/\|/, " ");
    },
  },
  methods: {
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    stop(e) {
      e.stopPropagation();
    },
    actionNumberInputBlur() {
      let number = Number(this.product.Number);
      if (number < 1) {
        this.product.Number = "1";
      }
    },
    actionIncrease() {
      let number = Number(this.product.Number);
      let oldNumber = number;

      if (number >= this.product.Inventory) {
        this.$commonMethod.showNotify({
          type: "error",
          titleMsg: this.$t.detailsInterpret.inventoryLimit,
        });
        return;
      }
      number++;

      this.product.Number = number + "";
      let num = number - oldNumber;
      this.$emit("numberChanged", this.product, num);
    },
    actionReduction() {
      let number = Number(this.product.Number);
      let oldNumber = number;
      if (number <= 1) return;
      number--;
      let num = number - oldNumber;
      this.product.Number = number + "";
      this.$emit("numberChanged", this.product, num);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/publicStyle";
@import "~assets/scss/var";

.product-cell {
  display: flex;
  padding: 19px 0 18px;
  align-items: flex-start;

  ::v-deep .checkbox {
    flex-shrink: 0;
    margin-top: 18px;
  }

  .product {
    flex-grow: 1;
    margin-left: 15px;
    display: flex;
    overflow: hidden;
    word-break: break-word;
    .photo {
      width: 56px;
      height: 56px;
      object-fit: cover;
      .ImgIcon {
        height: 56px;
        width: 56px;
        object-fit: cover;
      }
      ::v-deep .van-image__img {
        height: 56px;
        width: 56px;
        object-fit: cover;
      }
      ::v-deep .van-image__error {
        height: 56px;
        width: 56px;
        object-fit: cover;
      }
    }

    .content {
      margin-left: 13px;
      flex-grow: 1;

      p {
        margin: 0;
        padding: 0;
      }

      .title {
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 12px;
          margin-right: 5px;
        }
        .name {
          vertical-align: middle;
          display: inline-block;
          font-size: 14px;
          color: black;
        }
      }

      .detail {
        color: $-color-light-gray;
        font-size: 13px;
        margin-top: 10px;
      }

      .notice {
        color: $-color-red;
        font-size: 13px;
        font-weight: bold;
        margin-top: 10px;
      }

      .price-count {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .price {
          font-size: 12px;
          color: $-color-red;
          font-weight: bold;

          span {
            font-size: 16px;
          }
        }

        .count {
          display: flex;
          align-items: center;

          .remove,
          .add {
            width: 25px;
            height: 25px;
          }

          .field {
            box-sizing: border-box;
            background: none;
            outline: none;
            border: 0.5px solid $-color-background;
            text-align: center;
            font-size: 14px;
            border-radius: 5px;
            width: 33px;
            height: 25px;
            display: block;
            padding: 0;
            margin: 0 6px;
          }
        }
      }
    }
  }
}
</style>
