<template>
  <div class="checkbox" :class="value ? 'true' : 'false'" @click="actionTap">
    <i></i>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    actionTap() {
      this.$emit("input", !this.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  align-items: center;

  i {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.true i {
    background-image: url("~assets/img/shopping/icon_buy_tick_select.png");
  }

  &.false i {
    background-image: url("~assets/img/shopping/icon_buy_tick.png");
  }
}
</style>
