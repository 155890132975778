<template>
  <div class="product-section">
    <div class="header">
      <checkbox v-model="isSelected"></checkbox>
      <p class="title" @click="getShopId()">{{ shopName }}</p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import Checkbox from "./Checkbox";

export default {
  name: "ProductSection",
  components: {
    Checkbox
  },
  props: {
    shopName: String,
    shopId: Number,
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      test: true
    };
  },
  computed: {
    isSelected: {
      get() {
        return this.selected;
      },
      set(v) {
        this.$emit("select", v);
      }
    }
  },
  methods: {
    // 跳转商铺
    getShopId() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "store",
        name: "store",
        query: [
          {
            name: "ShopId",
            value: this.shopId
          }
        ]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.product-section {
  background-color: white;
  border-radius: 5px;
  padding: 22px 15px 0;
  box-shadow: 0px 2.5px 6.5px 1px rgb(0 0 0 / 10%);
  & > .header {
    display: flex;
    align-items: center;

    & > {
      ::v-deep .checkbox {
        flex-shrink: 0;
      }

      .title {
        margin: 0 0 0 15px;
        font-size: 12px;
        font-weight: bold;
        width:100%;
      }
    }
  }

  ::v-deep .product-cell {
    border-bottom: 0.5px solid #f6f6f6;

    &:last-child {
      border-bottom-width: 0;
    }
  }
}
</style>
